<template>
    <div class="container" style="display: flex; flex-direction:row; height: 100vh; align-items:center; justify-content:center;">
        <div style="display: flex; flex-direction:row; width: 100%; align-items:center; justify-content:center;">
            <div style="text-align: center; display: flex; flex-direction:column; align-items:center; justify-content:center; margin-top: 2%; width: 80%;">
                <h2 style="font-family: poppins;">¡Tu cuenta en Mundo Universitario ha sido creada!</h2>
                <h3 style="font-family: poppins;">Confirma tu cuenta vía correo electrónico para iniciar sesión</h3>
                <!-- <span style="font-size: 18px; justify-content: center;">
                    Bienvenido(a) a nuestra comunidad de estudiantes y profesionales. 
                    <br>
                    Es necesario confirmar tu cuenta desde el correo electrónico que te hemos enviado para poder iniciar sesión y                         
                    concluir tu registro, así podrás acceder a todas las funcionalidades que te ofrecemos.
                    <br>
                    ¡Empecemos juntos tu camino hacia el éxito profesional!
                    <br><br>
                </span> -->
                <router-link to="/login" style="color:#f5782d; font-weight: bold; font-size: 20px; text-decoration: none;">
                    INICIAR SESION
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
    localStorage.clear();
  },
};

</script>
  
<style land="scss" scoped></style>
  